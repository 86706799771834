<template>
  <header id="header" class="app-header bg-white border-bottom navbar navbar-expand-lg navbar-end navbar-light">
    <div class="container">
      <nav class="js-mega-menu navbar-nav-wrap">
        <button v-b-toggle.sidebar-backdrop class="navbar-toggler mt-2 border-0 p-0 me-3" type="button" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-default">
            <i class="bi-list"></i>
          </span>
          <span class="navbar-toggler-toggled">
            <i class="bi-x"></i>
          </span>
        </button>

        <a class="navbar-brand" :href="absoluteUrl()" aria-label="Brandboosta">
          <img class="navbar-brand-logo" :src="absoluteUrl('/images/logos/logo-dark.png')" alt="Logo">
        </a>

        <div class="collapse pe-lg-8 navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav justify-content-end">
            <li class="nav-item">
              <a class="nav-link" :href="absoluteUrl()">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :href="absoluteUrl('/about')">About</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :href="absoluteUrl('/projects/start')">Get a logo</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :href="absoluteUrl('/pricing')">Pricing</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :href="absoluteUrl('/portfolio')">Portfolio</a>
            </li>
          </ul>
        </div>
        <div class="order-3 ms-auto">
          <div class="d-inline-block">
              <b-dropdown size="sm" id="dropdown-right" right variant="link" class="user-dropdown-auth">
                    <template #button-content>
                        <a class="avatar avatar-sm p-0" href="#" id="profileDropdown" role="button" aria-expanded="false">
                            <img class="avatar-img rounded-circle" :src="absoluteUrl(activeUser.image)" alt="avatar">
                        </a>
                    </template>
                    <b-dropdown-item href="#" @click.prevent="$router.push({path: '/'})"><i class="bi-speedometer2 me-1"></i> Dashboard</b-dropdown-item>
                    <b-dropdown-item href="#" @click.prevent="$router.push({path: '/account'})"><i class="fa fa-address-book me-1"></i> Profile</b-dropdown-item>
                    <b-dropdown-item href="#" @click.prevent="logout()"><i class="bi-power me-1"></i> Logout</b-dropdown-item>
                </b-dropdown>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
    computed:{
      activeUser(){
        return this.$store.state.activeUser
      },
    },
    methods:{
      isVariableDefined(el){
          return typeof !!el && (el) != 'undefined' && el != null
      },
      stickyHeader() {
          var stickyNav = document.querySelector('.navbar-sticky');
          if (this.isVariableDefined(stickyNav)) {
              var stickyHeight = stickyNav.offsetHeight;
              stickyNav.insertAdjacentHTML('afterend', '<div id="sticky-space"></div>');
              var stickySpace = document.querySelector('#sticky-space');
              if (this.isVariableDefined(stickySpace)) {
                  document.addEventListener('scroll', function (event) {
                      var scTop = window.pageYOffset || document.documentElement.scrollTop;
                      if (scTop >= 400) {
                          stickySpace.classList.add('active');
                          document.querySelector("#sticky-space.active").style.height = stickyHeight + 'px';
                          stickyNav.classList.add('navbar-sticky-on');
                      } else {
                          stickySpace.classList.remove('active');
                          stickySpace.style.height = '0px';
                          stickyNav.classList.remove("navbar-sticky-on");
                      }
                  });
              }
          }
      },
      logout() {
        this.$store.dispatch("changeLoaderValue",true)
        this.$store.dispatch("logoutUser")
      },
    },
    async mounted(){
        this.stickyHeader()
    }
}
</script>

<style>
.app-header{
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    z-index: 1001;
}

.app-header .user-dropdown-auth .btn.btn-link{
  padding: 0;
}
</style>
